import {Fragment, useState} from 'react';
import ChartBuilder from '../../../../../../vendor/builders/Chart';
import  DateTimeRangePickerValue  from '../../../../../../vendor/builders/Frame/_layouts/search/_layouts/dates/date-time';
import { defaultFormatChartValue } from '../../../../../../bootstrap/Helpers/utils';
import moment from 'moment';

export default function CAStatsBySubfamilyRender (props) {

  let renderConfigs = props.data;
  let [period,setPeriod] = useState(null); 

  const changeDateValue = (e, value = null) => {
    formatPeriod(value);
    renderConfigs?.screenActions.handleSearch (e, value, 'date');
  };

  const formatPeriod = value => {
    if(value){
      const periods = value.split(",");
      const p = "Chiffre d'affaire du "+moment(periods[0]).format("DD/MM/YYYY HH:mm:ss") +" au "+ moment(periods[1]).format("DD/MM/YYYY HH:mm:ss") 
      setPeriod(p);
    }
  }

  const chartValue = defaultFormatChartValue(renderConfigs?.data);

  return (
    <Fragment>
      <div className="title text-white p-2">
        CHIFFRE D'AFFAIRE PAR SOUS FAMILLE D'ARTICLE
      </div>
      
      <div className="my-2 p-2">
        <DateTimeRangePickerValue
          size="small"
          changeSearchValue={changeDateValue}
          data={{
            name: 'date',
          }}
        />
      </div>
      <div>
        {
          period ? <p className='bg-dark text-white p-2 text-center'>
                      {period}
                    </p> : null
        }
        
        <p className='text-center'>
          Total : <span className='text-danger fw-bold'>
          {chartValue?.series?.reduce((total,current) => total + current, 0)?.toLocaleString('fr-FR')} F.CFA
            </span> 
        </p>
      </div>
      <div className='col-12 d-flex justify-content-center'>
        <ChartBuilder 
              dataset={chartValue?.dataset}
              series={[{ data : chartValue?.series }]} 
              xAxis={chartValue?.xAxis} 
              chartType="bar" 
          />
      </div>
    </Fragment>
  );
}
