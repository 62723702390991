import React from "react";
import CostingLogsRender from "./render";
import ProductsellspriceService from "../../../../../external-communication/services/productsellsprice.service";
import CostpriceService from "../../../../../external-communication/services/cost-price.service";

export default class CostingLogs extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            costings : [],
            articleId : null,
            articleName : null,
            params:{
                page: 0,
                size: 10,
            }
        }
        this.loadArticleInfo = this.loadArticleInfo.bind(this); 
        this.loadLogs = this.loadLogs.bind(this); 
    }


    async componentDidMount(){
        await this.loadArticleInfo(this.props.data.data);
        await this.loadLogs(this.state.params);
    }

    async loadArticleInfo(articleId){
        const articleInfoQuery  = await new ProductsellspriceService().one(articleId);
        if(articleInfoQuery.success){
            const article = articleInfoQuery.data.article;

            await this.setState({
                articleId : article.id, 
                articleName : article.name,
                params: {
                    ...this.state.params,
                    articleId: article.id
                }
            });


        }
    }

    async loadLogs(params = null){
        const queryCosting =  await new CostpriceService().all(params);

        if(queryCosting.success){
            await this.setState({costings : queryCosting.data.items});
        }
    }

    render(){
        return <CostingLogsRender params={this.state.params} changeParams={this.loadLogs} articleName={this.state.articleName} costings={this.state.costings} />
    }

}