import React, {Fragment} from 'react';
import {excludeFieldsFromArray} from '../../bootstrap/Helpers/utils';
import SingleContentFrame from '../builders/Single';
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style';
import moment from 'moment';
import appParams from '../../configs/app-params';
export default class BaseScreen extends React.Component {
  //name = extractClassName (this.constructor.name, 'Screen');
  name = null;
  template = null;

  constructor (props) {
    super (props);
    this.loadFrameContent = this.loadFrameContent.bind (this);
    this.loadDefaultTemplate = this.loadDefaultTemplate.bind(this);
    this.loadDefaultRender = this.loadDefaultRender.bind(this);
    this.loadFrameConfig = this.loadFrameConfig.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.openGoodDialog =  this.openGoodDialog.bind(this);
    this.loadOtherDialogContent = this.loadOtherDialogContent.bind(this);
    this.otherUpdate = this.otherUpdate.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.setSnackInformation = this.setSnackInformation.bind(this);
    this.onExport = this.onExport.bind(this);
    this.formatToExportList = this.formatToExportList.bind(this);

    this.state = {
      target: '',
      screenConfig: null,
      singleData : null, 
      formPayLoad : null,
      config : null,
      localOptions : [], 
      dialogInformation : {
          dialogState: {
              isOpen: false,
              openDialog : this.openGoodDialog,
              closeDialog : this.closeDialog,
          },
          dialogContent : null,
      },
      snackInformation : {
        isOpen : false,
        message : null, 
        color : 'info'
      },
      singleComponent : null,
      searchOptions : {}
    };
  }

  componentDidMount(){
    const config = this.loadFrameContent();
    this.setState({
      config : config,
      searchOptions : this.props.data.searchOptions,
      localOptions : this.props.data.options
    });
  }

  async resetForm(){
    this.props.data.screenActions.resetPayload();
  }

  getDialogContent(actionName,dataId = null){

   

    let content  = {};
    const config = this.loadFrameConfig();
    
    const fields = config?.fields ?? {};  
    switch(actionName){
      case 'add' : 
          const options = this.props.data?.options || [];
          const formFields = excludeFieldsFromArray(fields,config.excludeToForms);

          this.resetForm();
          const FormAddRender = this.loadFormRender() || null; 
          content = {
                        actionName : "form",
                        action : actionName,
                        componentName : config?.title || this.name,
                        fieldsData : null,
                        size : config?.dialogsConfig?.formDialog?.size || "sm",
                        formFields : {
                          renderComponent : FormAddRender,
                          formPayload : this.props.data?.formPayload,
                          handleFormField : this.props.data?.screenActions?.handleFormField,
                          handleSubFormField : this.props.data?.screenActions?.handleSubForm,
                          onSubmitForm : this.props.data?.screenActions?.submitForm,
                          loadFieldOptions : options,
                          fields : formFields,
                          formTitle : config?.formConfig?.addFormTitle,
                          data : config?.formConfig?.initValue
                        }
                    }; 
        break;
      case 'edit' :
          this.loadEditData(dataId);  
          
          const editFormFields = excludeFieldsFromArray(fields,config.excludeToForms || []);
          const FormEditRender = this.loadFormRender() || null;            
          content  = {
                        actionName : "form",
                        action : actionName,
                        componentName : config?.title || this.name,
                        fieldsData : null,
                        size : config?.dialogsConfig?.formDialog?.size || "sm",
                        formFields : {
                          renderComponent : FormEditRender,
                          formPayload : this.props.data?.formPayload,
                          handleFormField : this.props.data?.screenActions?.handleFormField,
                          onSubmitForm : this.props.data?.screenActions?.submitForm,
                          handleSubFormField : this.props.data?.screenActions?.handleSubForm,
                          loadFieldOptions : this.props.data?.options,
                          fields : editFormFields,
                          formTitle : config?.formConfig?.editFormTitle,
                          data : config?.formConfig?.initValue
                        }
                    }; 
      
        break;
      case 'view' :
            this.loadEditData(dataId);
            const donnees = this.state.singleData;
            const Render = this.state.singleComponent || this.loadSingleRender() || SingleContentFrame;            
            content = {
                actionName : actionName,
                data : {
                  title : config?.title || config?.name,
                  reference : dataId,
                  columns : excludeFieldsFromArray(fields,config.excludeToHeaders || []),
                  rows : donnees
                },
                size : config?.dialogsConfig?.singleDialog?.size || "sm",
                pDialogRender : Render
             };         
          break;  
      default: 
        content = this.loadOtherDialogContent(actionName,config?.title || this.name,dataId,config)
        break;
    }
    return content;
  }

  loadOtherDialogContent(actionName,title=null,dataId=null){
    return {
      actionName : actionName,
      componentName :  title,
      dataReference : dataId,
      validAction :this.props.data?.screenActions?.validOtherAction 
    } 
  }

  openGoodDialog(event, name = null, id= null){
    let actionName = name || event.target.name
    if(actionName === "export"){
      const config = this.loadFrameConfig();
      this.onExport(event,"export "+ config?.title+ ` ${moment(new Date()).format("DD/MM/YYY - HH:mm:ss")}`)
    }else{
      const dialogContent = this.getDialogContent(actionName, id);
      this.openDialog(dialogContent);
    }
  }

  formatToExportList = data => {
    const config = this.loadFrameConfig();
    const headers = excludeFieldsFromArray(config?.fields,config?.excludeToHeaders || []);
     
    return data.map(
      item => {
        
        let toExport = {}

        headers.forEach(
          header => {
            const key = header.title || header.name
            if(key !== "image"){
                let value = null; 

                const type = header?.type;


                switch(type?.toLowerCase()){
                  case 'objectfetcher' :
                        let text = ""; 


                        header?.fieldsSelected?.forEach(
                            field => {
                                text = text+" "+item?.[header?.name]?.[field];
                            }
                        );
                        value = text;
                     break;
                  case 'date': value = item?.[header?.name] ?
                      moment(item?.[header?.name]).format("DD/MM/YYYY") : null; 
                    break;
                  case 'money' : value =  Intl.NumberFormat(header?.devise || appParams.language,{ style: 'currency',
                    currency: header?.currency || appParams.currency,
                    maximumSignificantDigits: 3,
                        }).format(item?.[header?.name])    
                    break;
                  case 'datetime' :
                      value = item?.[header?.name] ? 
                                                  moment(item?.[header?.name]).format("DD/MM/YYYY hh:mm:ss"): null
                    break;
                  case 'checkbox' : 
                        const i = item?.[header?.name] ? 1 : 0;
                        value = header?.options[i]?.text
                      break;
                  case 'state-multiple' : 
                      value =  header?.options[item?.[header?.name]]?.text;
                  break;         
                  default : 
                      value = item?.[header?.name] ;
                    break;
                }

                toExport = {
                  ...toExport,
                  [key] : value
                }
            } 
            
          }
        );

        return toExport;
      }
    );
  }
  
  
  onExport = (e,fileName,dataToExport) => {
    e.preventDefault();
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8";
    const fileExtension = '.xlsx';

    const listForExtraction = this.props.data?.data?.items || [];

    const ws = XLSX.utils.json_to_sheet(this.formatToExportList(listForExtraction));
    const wb = {Sheets:{'data' : ws}, SheetNames:['data']};
    const excelBuffer = XLSX.write(wb,{bookType:'xlsx',type:'array'})
    const data = new Blob([excelBuffer],{type:fileType})
    FileSaver.saveAs(data,fileName+fileExtension);
  }

  openDialog = (dialogContent) => {
    this.setState(
      () => ({
        ...this.state,
        dialogInformation : {
          ...this.state.dialogInformation,
          dialogState : {
            ...this.state.dialogInformation.dialogState,
            isOpen : true,
          },
          dialogContent : dialogContent
        },
      })
    )
  }

  closeDialog = () => {
    this.setState(
      () => ({
        ...this.state,
        dialogInformation : {
          ...this.state.dialogInformation,
          dialogState : {
            ...this.state.dialogInformation.dialogState,
            isOpen : false,
          }
        },
        dialogContent : null
      })
    )
  }

  loadFrameContent () {
    const config = this.loadFrameConfig();
    return {
      ...config,
      template: this.template || this.loadDefaultTemplate(),
      render : this.loadDefaultRender(),
      fetchData : this.props.data?.data, 
      handleFormField : this.props.data?.screenActions?.handleFormField,
      handleSubFormField : this.props.data?.screenActions?.handleSubForm,
      onSubmitForm : this.props.data?.screenActions?.submitForm,
      otherActionExecution : this.props.data?.screenActions?.validOtherAction,
      formPayload : this.props.data?.formPayload,
      snackInformation : this.props.data?.snackInformation,
      searchOptions : this.props.data?.searchOptions,
      handleSearchOption : this.props.data?.screenActions?.handleSearch 
    };
  }

  loadFrameConfig () {
    const target = this.state.target || 'commons';
    try {
    let config =
      this.state.config ||
      require (`../../app-features/${target}/${this.name}/config`).default;

      return config;
    }catch(exception){
      throw new Error("Please provide a config");
    }
  }

  loadDefaultRender () {
    const target = this.state.target || 'commons';
    try {
      let render =
        this.state.render ||
        require (`../../app-features/${target}/${this.name}/render`).default;
      return render;
    } catch (exception) {
      return null;
    }
  }

  loadSingleRender () {
    const target = this.state.target || 'commons';
    
    try {
      let render =
        this.state.render ||
        require (`../../app-features/${target}/${this.name}/_layouts/single`).default;
      return render;
    } catch (exception) {
      return null;
    }
  }

  loadFormRender () {
    const target = this.state.target || 'commons';
    try {
      let render =
        this.state.render ||
        require (`../../app-features/${target}/${this.name}/_layouts/form`).default;
        return render;
    } catch (exception) {
      return null;
    }
  }

  loadDefaultTemplate () {
    try{
      const loadTemplate = require (`../template/${this.state.target}`).default;
      return loadTemplate;
    }catch(exception){
      throw new Error("Please provide a default template");
    }
    
  }

  loadEditData(dataId){
    this.props.data.screenActions.loadSingleData(dataId);
  }

  async componentDidUpdate(prevProps,prevState){
      if(this.props?.data?.data !== prevProps?.data?.data){
        await this.setState({
          payLoadForm : this.props.data.formPayload,
          config : {
            ...this.state.config,
            fetchData : this.props.data?.data,
          }      
        });
      } 

      if(this.props.data?.searchOptions !== prevProps.data?.searchOptions){
          await this.setState({
            payLoadForm : this.props.data.formPayload,
            config : {
              ...this.state.config,
              searchOptions : this.props.data?.searchOptions,
            }      
          });
      }

      if(this.props.data?.options !== prevProps.data?.options){
        await this.setState({
          payLoadForm : this.props.data.formPayload,
          config : {
            ...this.state.config,
            options : this.props.data?.options,
          }      
        });
      }

      if(this.props.data?.formPayload !== prevProps.data?.formPayload){
        await this.setState({
          dialogInformation : {
              ...this.state.dialogInformation,
              dialogContent : {
                ...this.state.dialogInformation.dialogContent,
                fieldsData : this.props.data?.formPayload
              }
          },
          formPayLoad : this.props.data.formPayLoad,
        });
      }
      
      if(this.props.data?.singleData !== prevProps.data?.singleData){
        await this.setState({
          dialogInformation : {
              ...this.state.dialogInformation,
              dialogContent : {
                ...this.state.dialogInformation.dialogContent,
                data : {
                  ...this.state.dialogInformation.dialogContent.data,
                  rows : this.props.data.singleData
                },
              }
          },
          singleData : this.props.data.singleData,
        })
      }
      
      if(this.props.data.closeDialog !== prevProps.data?.closeDialog){
        this.closeDialog();
      }

      if(this.props?.data?.snackInfo !== prevProps?.data?.snackInfo){
          this.setSnackInformation(this.props.data.snackInfo);
      }
    
      this.otherUpdate(prevProps);
  }

  async setSnackInformation(snackData){
      await this.setState({
          snackInformation : {
            message : snackData.message || null,
            color : snackData.color || 'info',
            isOpen : snackData.isOpen || false
        }
      })
  }


  otherUpdate(prevProps){}

  render = () => {
    const Template =this.state.config?.template;
    if(Template){
      return (
        <Fragment>
           <Template 
              target={this.state.target} 
              frameConfigs={this.state.config}
              dialogInformation={this.state.dialogInformation}
              snackBarInformation = {this.state.snackInformation}
              searchOptionInformation = {this.state.searchOptions}
            />
        </Fragment>
      )
    }
    return <div>Loading</div>
    
  }
}
