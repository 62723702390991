import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Fragment } from "react";
import fiche from '../../../../../assets/images/fiche.jpg';


export default function GlobalCosting(props){

    const factoryCostList = props.costings?.factoryCostList || {};
    const materialCost = props.costings?.materialCost || {};
    const otherChargeCostList = props.costings?.otherChargeCostList || {};

    return  <div>
                <div className="card p-3 m-1">
                    <div className="d-flex align-items-center">
                        <div className="image bg-light p-2 mr-3">
                            <img src={fiche} alt="logo" className="rounded" width="120" />
                        </div>
                        <div className="p-2 w-100"> 
                            <h5 className="mb-0 mt-0">COUT DE REVIENS ARTICLE {props.articleName?.toUpperCase()}</h5>
                            <div className="p-2 mt-2 bg-primary d-flex justify-content-between rounded text-white stats">
                                <div className="d-flex flex-column">
                                    <span className="number1">
                                        {props.costings?.costPrice?.toLocaleString("fr-FR")} 
                                    </span>
                                </div>
                            </div>
                        </div>     
                    </div>
                </div>                
                <div className="card-content h-100 m-1">
                    <div className="card-body">
                        <CostingTable
                            title="Cout matiere"
                            emptyMessage="Aucune ligne trouve"
                            data={materialCost?.coutMatiereList}
                            total={materialCost?.totalCoutMatiere}
                            headers={
                                [
                                    {
                                        name : 'recipe',
                                        title : "Recette"
                                    },
                                    {
                                        name : 'qty',
                                        title : "Quantite"
                                    },
                                    {
                                        name : 'unitPrice',
                                        title : "Prix unitaire"
                                    },
                                    {
                                        name : 'total',
                                        title : "Total"
                                    },
                            ]}
                        />

                        <CostingTable
                            title="Cout des charges directes"
                            emptyMessage="Aucune ligne trouve"
                            data={factoryCostList?.coutUsineList}
                            total={factoryCostList?.totalCoutUsine}
                            headers={[
                                {
                                    name : 'directCharge',
                                    title : "Charges directes"
                                },
                                {
                                    name : 'value',
                                    title : "Valeur"
                                },
                                {
                                    name : 'productionVolume',
                                    title : "Volume"
                                },
                                {
                                    name : 'cuod',
                                    title : "CUO"
                                },
                                {
                                    name : 'total',
                                    title : "TOTAL"
                                },
                            ]}
                        />

                        <CostingTable
                            title="Cout des charges indirectes"
                            emptyMessage="Aucune ligne trouve"
                            data={otherChargeCostList?.autreChargeList}
                            total={otherChargeCostList?.totalAutreCharge}
                            headers={[
                                {
                                    name : 'indirectCharge',
                                    title : "Charges indirectes"
                                },
                                {
                                    name : 'value',
                                    title : "Valeur"
                                },
                                {
                                    name : 'allProductionVolume',
                                    title : "Volume"
                                },
                                {
                                    name : 'cuoind',
                                    title : "CUO"
                                },
                                {
                                    name : 'total',
                                    title : "TOTAL"
                                },
                            ]}
                        /> 
                    </div>
                </div>
            </div>
}


const CostingTable = props => {
    const headers = props.headers || [];
    const data = props.data || [];

    return <Fragment>
                <div className="d-flex flex-row justify-content-between p-3 text-white  costing-title mb-2">
                   <h6>{props.title.toUpperCase()}</h6>

                    <span className="bg-light fw-bold text-dark px-2">
                        TOTAL : {(props.total || 0)?.toLocaleString('fr-FR')}
                    </span>
                   
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 m-0">
                    <div className="table-responsive">
                        <TableContainer component={Paper}>
                        <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                            <TableHead sx={{ "& th": {
                                color: "white",
                                backgroundColor: "black"
                                }}}>
                                <TableRow>
                                    {
                                        headers.map(
                                            header => {
                                                return <TableCell key={header.name} sx={{ fontWeight:"bold" }}>{header.title.toUpperCase()}</TableCell>
                                            } 
                                        )
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    
                                    data?.length > 0 ?  
                                    <Fragment>
                                            {
                                                data?.map((row,index) => {
                                                    return (
                                                            <TableRow
                                                                key={row.id}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                
                                                                {
                                                                    headers.map(
                                                                        header => {
                                                                            return  <TableCell key={props.title+row?.[header.name]} sx={{ textAlign : 'left',fontWeight : 'bold' }}>
                                                                                        {row?.[header.name]?.toLocaleString("fr-FR").toUpperCase()}
                                                                                    </TableCell>
                                                                        } 
                                                                    )
                                                                }
                
                                                            </TableRow>
                                                    )})
                                            }
                                    </Fragment>
                                    
                                    : 
                                        <TableRow>
                                            <TableCell sx={{ textAlign:"center" }} colSpan={5} component="td" scope="row">
                                                {props.emptyMessage } :)
                                            </TableCell>
                                        </TableRow>
                                }
                                
                            </TableBody>
                        </Table>
                        </TableContainer>
                    </div>
                </div>        
            </Fragment>
}