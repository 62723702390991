import {useState} from 'react';
import './costing.css';
import {InputTypeText} from '../../../../../vendor/builders/Form/Input';
import GlobalCosting from './global-costing';

export default function CostingRender (props) {
  return (
    <div className="costing-container">
      <div className="p-2 text-center text-white costing-title">
        <h4>FICHE D'EVALUATION DU COUT DE REVIENS</h4>
      </div>

      <PackagingForm
        state={props.endProcessing}
        submitPackagingCosting={props.makeCosting}
      />
      {props.endProcessing
        ? <GlobalCosting
            articleName={props.articleName}
            costings={props.costings}
          />
        : <div className="col-12 p-5 text-center fw-bold text-danger">
            Patientez svp le temps de calculer le cout de reviens
          </div>}
    </div>
  );
}

const PackagingForm = props => {
  const [negoceCost, setNegoceCost] = useState (null);
  const [negoceCostError, setNegoceCostError] = useState (null);

  return (
    <div className="row my-3">
      <div className="col-12 col-md-9 col-lg-9 col-xl-9">
        <InputTypeText
          error={negoceCostError}
          value={negoceCost}
          changeValue={e => {
            setNegoceCost (e.target.value);
          }}
          fieldInformation={{
            name: 'negoceCost',
            type: 'number',
            label: 'Cout du packaging',
            size: 'small',
            disable: !props.state,
          }}
        />
      </div>
      <div className="col-12 col-md-3 col-lg-3 col-xl-3 mt-2">
        <button
          disabled={!props.state}
          className="btn btn-sm btn-success p-2"
          onClick={e => {
            if (negoceCost && negoceCost !== '') {
              setNegoceCostError (null);
              props.submitPackagingCosting (negoceCost);
            } else {
              setNegoceCostError ('Le champ packaging est requis');
            }
          }}
        >
          CALCULER COSTING
        </button>
      </div>
    </div>
  );
};
