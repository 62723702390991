import DefaultQuery from '../../vendor/bases/default.query';

export default class BestsellerQuery extends DefaultQuery {
  constructor (params = null) {
    super ({
      ...params,
      path: 'cost-price',
    });
  }

  async readAll (params = null) {
    return await this.getMethod (
      this.buildPath ('list'),
      params
    );
  }
}
