import BaseBackScreen from '../base-back-screen';
import QuittanceRender from './_layouts/quittance';

export default class SellsScreen extends BaseBackScreen {
    name = 'Sells';

    loadOtherDialogContent(actionName,title=null,dataId=null){
    let otherContent = {};
    if(actionName === 'quittance'){
        this.loadEditData(dataId);
        const donnees = this.state.singleData;
        let data = {
           rows : donnees
        };  
        const config = this.loadFrameContent();
        otherContent = {
            actionName : actionName,
            componentName :  title,
            pDialogRender : QuittanceRender,
            data : data,
            size : config?.dialogsConfig?.singleDialog?.size || "sm",
        }
        
    }else{
        otherContent = {
            actionName : actionName,
            componentName :  title,
            dataReference : dataId,
            validAction :this.props.data?.screenActions?.validOtherAction 
        }
    }
    
    return otherContent;
    }
}
