import {  Box, Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { useState } from "react";
import moment from "moment";
import FrameTitle from "../../../../../vendor/builders/Frame/_layouts/title";

export default function CostingLogsRender(props){
    const [page, setPage] = useState(props.params?.page || 0);
    const [rowsPerPage, setRowsPerPage] = useState(props.params?.size || 10);
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.changeParams({
            ...props.params,
            page : newPage,
        })
    };
    
    const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    props.changeParams({
        ...props.params,
        page : 0,
        size : +event.target.value
    });
    };


    return  <div className="">
                <FrameTitle
                    name={"costing-logs"}
                    title={"Historique des couts de reviens #"+props.articleName?.toUpperCase() }
                    openDialog={(e) => {}}
                    actions={[]}
                />

    
    <Box sx={{ width: '100%' , my:3}}>     
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 450,border: 1 }}>
          <Table stickyHeader size={'small'}>
            <TableHead >
              <TableRow sx={{ backgroundColor : 'black', color : 'white'}}>
                <StyledTableCell>
                  Article
                </StyledTableCell>
                <StyledTableCell>
                  Cout de reviens
                </StyledTableCell>
                <StyledTableCell>
                  Date
                </StyledTableCell>
              </TableRow>
           </TableHead>
            <TableBody>
              {
                props?.costings?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(
                  (option,index) => <StyledTableRow  key={option.customerName}>
                                <StyledTableCell >
                                  {option.article.name}
                                </StyledTableCell>
                                <StyledTableCell >
                                  {option?.costPrice?.toLocaleString("fr-FR")}
                                </StyledTableCell>
                                <StyledTableCell >
                                  {moment(option?.date).format("DD/MM/YYYY") }
                                </StyledTableCell>
                              
                            </StyledTableRow>

                )
              }
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={props?.costings?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>  
    </Box>
            </div>
}





const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
