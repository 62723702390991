import customerInfo from '../../../../../configs/customer';
import moment from 'moment';
import {Fragment, useEffect, useRef} from 'react';
import {useReactToPrint} from 'react-to-print';
import {Button} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  Line,
  Printer,
  Text,
} from 'react-thermal-printer';

export default function Quittance (props) {
  const sellingInfo = props.sell;

  const componentRef = useRef ();

  const onPrint = useReactToPrint ({
    margin: 0,
    content: () => componentRef.current,
    jdPDF: { unit: "mm", format: "a5", orientation: "portrait" }
  });

  useEffect (() => {
    if (props.directPrint) {
      onPrint ();
    }
  });

  return (
    <Fragment>
      <div className="d-flex mx-2 mt-1 mb-1  border-bottom">
        {!props.directPrint
          ? <Button
              variant="outlined"
              sx={{color: 'white', bgcolor: 'green', mr: 1, mb: 2}}
              startIcon={<FontAwesomeIcon icon="print" />}
              onClick={onPrint}
            >
              Imprimer
            </Button>
          : null}

      </div>
      <div className="container">
        <div className="d-flex justify-content-center">
          <div className="col-7">
            <div
              className="box-left"
              ref={componentRef}
              style={{width: '100%'}}
            >
              <Printer type="epson" width={50} characterSet="french">
                
                
                <div className="h7">
                

                <table className="table">
                    <thead>
                        <tr>
                            <td></td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='h8'>
                            <td className="text-bold text-center">
                                <Text>
                                    {customerInfo?.name}
                                </Text>
                            </td> 
                            
                            </tr>
                            <tr className='h8'>
                                <td className='text-bold text-center'>
                                    <Text> {customerInfo?.address} </Text>
                                </td>
                            </tr>
                            
                            <tr className='h8'>
                                <td className='text-bold text-center'>
                                    <Text> {customerInfo?.tel} </Text>
                                </td>
                            </tr>
                    </tbody>
                </table>

                <Line />
                <div className='receipt-header p-1'>
                    <table className="table">
                        <thead>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='h8'>
                                <td className='text-start'> <Text>Caisse</Text></td>
                                <td className="text-start"> <Text>{sellingInfo?.cashRegisterNumber?.toUpperCase()}</Text></td>
                                <td colSpan={2} className="text-end"> <Text>{sellingInfo?.paymentType?.toUpperCase()}</Text></td>
                            </tr>
                            <tr className='h8'>
                                <td className='text-start'> <Text>Client</Text></td>
                                <td  className="text-start"> <Text>{sellingInfo?.customer?.firstName}</Text></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr className='h8'>
                                <td className='text-start'> <Text>N°</Text></td>
                                <td className="text-start"> <Text>{sellingInfo?.sellingNumber?.toUpperCase()}</Text></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr className='h8'>
                                <td className='text-start'> <Text>Le </Text></td>
                                <td  className="text-start"> <Text>{moment(sellingInfo?.date).format("DD/MM/YYYY")}</Text></td>
                                <td  className="text-start"> <Text> à </Text></td>
                                <td  className="text-start"> <Text>{moment(sellingInfo?.date).format("HH:mm:ss")}</Text></td>
                            </tr>
                            <tr className='h8'>
                                <td className='text-start'> <Text>Nbre article</Text></td>
                                <td colSpan={3} className="text-start"> <Text>{sellingInfo?.articleSoldList?.length}</Text></td>
                            </tr>
                        </tbody>       
                    </table>
                    
                    
                </div>
                <Line />

                <div className="table-responsive receipt-table">
                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th className="h8">
                                    <Text>
                                        DESIGNATION
                                    </Text>
                                </th>
                                <th className="h8 text-center">
                                    <Text>
                                        P.U.
                                    </Text>
                                </th>
                                <th className="h8 text-center">
                                    <Text>
                                        QTE
                                    </Text>
                                </th>
                                <th className="h8 text-center">
                                    <Text>
                                       TOTAL
                                    </Text>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                sellingInfo?.articleSoldList?.length > 0 ?   
                                sellingInfo?.articleSoldList?.map((row,index) => (
                                    <tr key={row.id} className='h8'>
                                        <td className='text-bold'>
                                            <Text> {row?.article?.name.toUpperCase()} </Text>
                                        </td>
                                        <td className="text-bold text-center">
                                            <Text>{row?.unitPrice?.toLocaleString('fr-FR')}</Text>
                                        </td> 
                                        <td className='text-center text-bold'>
                                            <Text>
                                                {row?.qty?.toLocaleString('fr-FR')} 
                                            </Text>
                                        </td>
                                        
                                        <td className='text-center text-bold'>
                                            <Text>
                                                {(row?.unitPrice * row?.qty)?.toLocaleString('fr-FR')} 
                                            </Text>
                                        </td>
                                    </tr>
                                )): <tr>
                                    <td className="text-center" colSpan={4}>
                                        Aucun article sur cette fiche de vente :)
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <hr/>

                <table className="table">
                    <thead>
                        <tr>
                            <td></td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='h8'>
                            <td className='text-bold text-start'>
                                <Text> REMISE </Text>
                            </td>
                            <td className="text-bold text-end">
                                <Text>
                                {
                            Intl.NumberFormat('fr-FR',{ style: 'currency',
                                                currency: 'XOF',
                                                
                                        }).format(sellingInfo?.discount)
                        }
                                </Text>
                            </td> 
                            
                            </tr>
                            <tr className='h8'>
                            <td className='text-bold text-start'>
                                <Text> TVA </Text>
                            </td>
                            <td className="text-bold text-end">
                                <Text>
                                    {Intl.NumberFormat('fr-FR',{ style: 'currency',
                                                currency: 'XOF',
                                        }).format(sellingInfo?.totalcostHt * sellingInfo?.tvaValue / 100)
                                    }
                                </Text>
                            </td> 
                            
                            </tr>
                            <tr className='h8'>
                            <td className='text-bold text-start'>
                                <Text> TOTAL TTC </Text>
                            </td>
                            <td className="text-bold text-end">
                                <Text>
                                    {Intl.NumberFormat('fr-FR',{ style: 'currency',
                                                    currency: 'XOF',
                                                    
                                            }).format(sellingInfo?.netToPaid)
                                        }
                                </Text>
                            </td> 
                            
                            </tr>

                            <tr className='h8'>
                            <td className='text-bold text-start'>
                                <Text>ESPECE</Text>
                            </td>
                            <td className="text-bold text-end">
                                <Text>
                                    {Intl.NumberFormat('fr-FR',{ style: 'currency',
                                                currency: 'XOF',
                                                
                                        }).format(sellingInfo?.amountPaid)
                                    }
                                </Text>
                            </td> 
                            
                            </tr>
                            <tr className='h8'>
                            <td className='text-bold text-start'>
                                <Text>MONNAIE A RENDRE</Text>
                            </td>
                            <td className="text-bold text-end">
                                <Text>
                                    {Intl.NumberFormat('fr-FR',{ style: 'currency',
                                                currency: 'XOF',
                                                
                                        }).format(sellingInfo?.currency)
                                    }
                                </Text>
                            </td> 
                            
                            </tr>
                            <tr className='h8'>
                            <td className='text-bold text-start'>
                                <Text>MONNAIE RENDU</Text>
                            </td>
                            <td className="text-bold text-end">
                                <Text>
                                {Intl.NumberFormat('fr-FR',{ style: 'currency',
                                            currency: 'XOF',
                                            
                                    }).format(sellingInfo?.currency)
                                }
                                </Text>
                            </td> 
                            
                            </tr>               
                    </tbody>
                </table>
                
                
                <Line />
                <div className="h7 mt-1">
                    <p className="text-center">
                        <Text align="center">MERCI DE VOTRE VISITE</Text>     
                    </p>
                </div>
                </div>
                
              </Printer>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
