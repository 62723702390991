import React from 'react';
import CostringRender from './render';
import CostpriceService from '../../../../../external-communication/services/cost-price.service';
import ProductsellspriceService from '../../../../../external-communication/services/productsellsprice.service';

export default class CostingComponent extends React.Component{
    constructor(props){
        super(props);
        this.makeCosting = this.makeCosting.bind(this);
        this.state = {
            costings : null,
            articleId : null,
            articleName : null,
            endProcessing : true
        }
    }

    makeCosting = async (negoceCost) => {
        await this.setState({endProcessing : false});

        const queryCosting =  await new CostpriceService().create({
            articleId : {value : this.state.articleId},
            negoceCost : {value : negoceCost.replace(" ","")},
        });

        if(queryCosting.success){
            await this.setState({costings : queryCosting.data,endProcessing : true});
            this.props.data.data.reloadTableInformation();
        }
    }

    async componentDidMount(){
        await this.loadArticleInfo(this.props.data.data.productId);
    }

    async loadArticleInfo(articleId){
        const articleInfoQuery  = await new ProductsellspriceService().one(articleId);
        if(articleInfoQuery.success){
            const article = articleInfoQuery.data.article;
            await this.setState({
                articleId : article.id, 
                articleName : article.name,
            })
        }
    }

    


    render(){
        return <CostringRender endProcessing={this.state.endProcessing} articleName={this.state.articleName} costings={this.state.costings} makeCosting={this.makeCosting} />
    }
}