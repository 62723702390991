import BaseBackScreen from '../base-back-screen';
import CostingComponent from './_layouts/costing';
import CostingLogs from './_layouts/costing-logs';

export default class ProductsellspriceScreen extends BaseBackScreen {
  name = 'Productsellsprice';

  loadOtherDialogContent(actionName,title=null,dataId=null){
    let otherContent = {};

    
    if(actionName === 'costing'){
        const config = this.loadFrameContent();
        otherContent = {
            actionName : actionName,
            componentName :  title,
            pDialogRender : CostingComponent,
            data : {
               productId :  dataId,
               reloadTableInformation : this.props.data.screenActions.reloadData
            },
            size : config?.dialogsConfig?.costingDialog?.size || "md",
        }
    }else if(actionName === "costing-logs"){
        const config = this.loadFrameContent();
        otherContent = {
            actionName : actionName,
            componentName :  title,
            pDialogRender : CostingLogs,
            data : dataId,
            size : config?.dialogsConfig?.costingLogsDialog?.size || "md",
        }
    }
    
    return otherContent;
  }
}
