import React from 'react';
import SummarychargeandproductComponent
  from './../../../../Summarychargeandproduct/index';
import {Box} from '@mui/material';

export default function ChargeProductResume () {
  return (
    <Box sx={{width: '100%', marginBottom:"10px"}}>
      <SummarychargeandproductComponent />
    </Box>
  );
}
